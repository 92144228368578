<template>
  <div>
    <b-input-group class="bg-white border rounded">
      <b-form-input
        v-model="form.query"
        v-mask="phoneMaskString"
        type="search"
        placeholder="Введите номер телефона"
        class="border-0"
        @input="deferredRequest"
        @keyup.enter="search"
      />
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="rounded-0 border-0"
        @click="search"
      >
        <b-icon icon="search" />
      </b-button>
    </b-input-group>

    <div class="d-flex justify-content-between align-items-end mt-3 py-1">
      <div class="d-flex">
        <div>
          <div class="mb-2 font-weight-bold">
            Период <span class="small">(От-До)</span>
          </div>

          <div class="d-flex">
            <div>
              <base-date-picker
                v-model="form.startDate"
                :max-date="maxDate"
                @change="updateForm"
              />
            </div>
            <div class="ml-2">
              <base-date-picker
                v-model="form.endDate"
                :max-date="maxDate"
                @change="updateForm"
              />
            </div>
          </div>
        </div>

        <div class="ml-3">
          <div class="mb-2 font-weight-bold">
            Время <span class="small">(От-До)</span>
          </div>

          <div class="d-flex">
            <div>
              <b-form-timepicker
                v-model="form.startTime"
                :label-no-time-selected="labelNoTimeSelected"
                :label-close-button="labelCloseButton"
                locale="ru"
                size="sm"
                @input="updateForm"
              />
            </div>
            <div class="ml-2">
              <b-form-timepicker
                v-model="form.endTime"
                :label-no-time-selected="labelNoTimeSelected"
                :label-close-button="labelCloseButton"
                locale="ru"
                size="sm"
                @input="updateForm"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="ml-2 d-flex">
        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          class="ml-auto border-0"
          size="sm"
          @click="clearFilters"
        >
          Сбросить фильтры
        </b-button>

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="ml-2"
          @click="search"
        >
          Поиск
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseDatePicker } from '@/components/base';

export default {
  name: 'SmsSearchPanel',
  components: {
    BaseDatePicker,
  },

  data() {
    return {
      labelNoTimeSelected: 'Не выбрано',
      labelCloseButton: 'Закрыть',
      form: {
        query: '',
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
      },
      maxDate: new Date(),
      deferred: null,
    };
  },
  computed: {
    phoneMaskString() {
      return this.form.query.startsWith('8') ? '###########' : '+###########';
    },
  },
  methods: {
    deferredRequest() {
      clearTimeout(this.deferred);
      this.updateForm();

      this.deferred = setTimeout(() => {
        this.search();
      }, 1200);
    },
    clearFilters() {
      this.form = {
        query: '',
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
      };

      this.$emit('clearFilters');
    },
    updateForm() {
      this.$emit('updateForm', this.form);
    },
    search() {
      this.$emit('search');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
