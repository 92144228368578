<template>
  <div class="w-100 pt-4">
    <b-container fluid="xl">
      <div class="mb-3 d-flex align-items-center justify-content-between">
        <div class="h3 mb-0">
          Сообщения
        </div>

        <div
          v-if="checkFeatureAccess({ name: 'Новое сообщение, рассылка сообщений', url: '/sms' })"
          class="d-flex"
        >
          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="text-nowrap"
            @click="openSmsSendModal"
          >
            Новое сообщение
          </b-button>

          <b-button
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="ml-2 text-nowrap"
            @click="openSmsSendingModal"
          >
            Рассылка сообщений
          </b-button>
        </div>
      </div>

      <SmsSearchPanel
        @clearFilters="changeFilter(true)"
        @updateForm="updateForm"
        @search="changeFilter(false)"
      />

      <div
        v-if="searchHasMade"
        class="mt-2"
      >
        <div v-if="!totalCount">
          Ничего не найдено
        </div>

        <template v-else>
          <div class="messages-info-wrapper">
            <div
              v-if="isLoading"
              class="d-flex justify-content-center mt-5"
            >
              <b-spinner
                label="Loading..."
                variant="primary"
                style="width: 5rem; height: 5rem;"
              />
            </div>

            <sms-info
              v-for="(message, index) in messages"
              :key="index"
              :message="message"
              class="message-info mt-3"
            />
          </div>

          <div class="d-flex align-items-center mt-3">
            <b-dropdown
              id="dropdown-1"
              :text="`${limit}`"
              split
              class="mr-2 bg-white"
              size="sm"
              split-variant="outline-primary"
              variant="primary"
            >
              <b-dropdown-item @click="tableSizeHandler(10)">
                10
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="tableSizeHandler(25)">
                25
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="tableSizeHandler(50)">
                50
              </b-dropdown-item>
            </b-dropdown>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="limit"
              class="m-0"
              @input="() => messagesFetch()"
            />
          </div>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import {
  differenceInMinutes, set, startOfToday, formatISO,
} from '@evd3v/date-fns';
import { dateWithoutTime } from '@/helpers/utils';

import SmsSearchPanel from '@/components/Sms/SmsSearchPanel';
import Bus from '@/eventBus';
import SmsInfo from '@/components/Sms/SmsInfo';

const LIMIT = 25;

export default {
  name: 'SmsNew',
  page: {
    title: 'CRM Doctis - Сообщения',
  },
  components: {
    SmsInfo,
    SmsSearchPanel,
  },
  mixins: [mixinRoles],

  data() {
    return {
      query: '', // still empty
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      searchHasMade: false,

      filter: {
        query: '',
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
      },

      isAllItemsLoaded: false,
      isLoading: false,
      limit: LIMIT,
      currentPage: 1,
      rows: null,
      mask: ['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    };
  },

  computed: {
    messages() {
      return this.$store.state.Sms.messages;
    },
    totalCount() {
      return this.$store.state.Sms.totalCount;
    },
    computedStartTime() {
      if (!this.startTime) return;

      const now = startOfToday();
      const startFormattedTime = set(now, { hours: this.startTime.slice(0, 2) || 0, minutes: this.startTime.slice(3, 5) || 0 });

      return differenceInMinutes(startFormattedTime, now);
    },
    computedEndTime() {
      if (!this.endTime) return;

      const now = startOfToday();
      const endFormattedTime = set(now, { hours: this.endTime.slice(0, 2) || 0, minutes: this.endTime.slice(3, 5) || 0 });

      return differenceInMinutes(endFormattedTime, now);
    },
  },

  created() {
    Bus.$on('messages:update', () => this.messagesFetch(true));
  },
  beforeDestroy() {
    this.$store.commit(this.$types.MESSAGES_SET, []);
    Bus.$off('messages:update', () => this.messagesFetch(true));
  },

  methods: {
    tableSizeHandler(value) {
      this.limit = value;
      this.messagesFetch(true);
    },
    updateForm(form) {
      // console.log(form);

      this.query = form.query;
      this.startDate = form.startDate;
      this.endDate = form.endDate;
      this.startTime = form.startTime;
      this.endTime = form.endTime;
    },
    async changeFilter(clear) {
      if (clear) {
        this.query = '';
        this.startDate = null;
        this.endDate = null;
        this.startTime = null;
        this.endTime = null;
      }

      this.filter = {
        query: this.query,
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
      };

      await this.messagesFetch(true);
    },
    openSmsSendModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsSendModal',
        props: {
          numberProp: null,
          name: null,
        },
      });
    },
    openSmsSendingModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsSending',
        props: {
          numberProp: null,
          name: null,
        },
      });
    },
    async messagesFetch(clear) {
      if (this.isLoading) return;
      if (clear) {
        this.currentPage = 1;
      }

      // this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = (this.currentPage - 1) * this.limit;
      const prevQuery = this.filter.query;

      try {
        await this.$store.dispatch(this.$types.MESSAGES_FETCH, {
          skip: prevTotal,
          take: this.limit,
          query: prevQuery,
          startDate: this.startDate ? dateWithoutTime(formatISO(this.startDate)) : null,
          endDate: this.endDate ? dateWithoutTime(formatISO(this.endDate)) : null,
          startTimeMinutes: this.computedStartTime,
          endTimeMinutes: this.computedEndTime,
          clear: true,
          save: true,
        });

        this.searchHasMade = true;

        // if (prevTotal + this.limit > this.totalCount) {
        //   this.isAllItemsLoaded = true;
        // }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;

        if (this.filter.query !== prevQuery) this.messagesFetch(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messages {
  display: flex;
  flex-direction: column;
  width: 100%;
}

::v-deep.crm-icon-search:hover {
  cursor: pointer;
  path {
    fill: $blue;
  }
}
.message-list {
  height: 100vh;
}

::v-deep.timepicker {
  width: auto;

  .display-time {
    width: 100%;
  }
}

.messages-info-wrapper {
  height: calc(100vh - 265px);
  overflow: auto;
}
</style>
